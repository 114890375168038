import { Component } from '@fullcalendar/core/component/Component';
// import { AulaStreamingComponent } from './../../aula-streaming/aula-streaming.component';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import swal from 'sweetalert2'

@Injectable({
  providedIn: 'root'
})
export class CandeactivateService {

  constructor() { }

  // canDeactivate(component: AulaStreamingComponent): any | Promise<boolean|UrlTree> {
  //   return component.exit().then((result) => { return result });
    
  // }
}
