import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ProcessosSeletivosService } from 'src/app/services/processos-seletivos/processos-seletivos.service';

@Component({
  selector: 'app-processo-seletivo-list',
  templateUrl: './processo-seletivo-list.component.html',
  styleUrls: ['./processo-seletivo-list.component.css']
})
export class ProcessoSeletivoListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  processos: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  id_usuario: any;
  level_access: any;

  constructor(
    public processosSeletivosService: ProcessosSeletivosService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { }

  
  getAll() {
    this.spinner.show();
    this.processosSeletivosService.getAll().subscribe(data => {
      if (data != 'null') {
        const response = (data as any);
        this.processos = response;
        this.spinner.hide();
        // this.rerender();
        this.dtTrigger.next();
      }
    }, error => {
      this.spinner.hide();
      console.error(error);
    });
  }

  delete(id) {
    this.spinner.show();
    if (confirm("Deseja realmente deletar este registro?")) {
      this.processosSeletivosService.delete(id).subscribe(
        data => {
          const response = (data as any);
          if (response.sucesso == true) {
            this.toastr.success('Registro excluído com sucesso!', 'Sucesso!');
            this.getAll();
          } else {
            this.toastr.error('Erro ao excluir registro!', 'Erro!');
          }
          this.spinner.hide();          
        }, error => {
          this.spinner.hide();
          console.error(error);
        }
      )
    }
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Portuguese-Brasil.json"
      },
      "bDestroy": true
    }

    this.getAll();
  }


}
