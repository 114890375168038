import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../../services/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-nova-senha',
  templateUrl: './nova-senha.component.html',
  styleUrls: ['./nova-senha.component.css']
})
export class NovaSenhaComponent implements OnInit {
  token: any;
  tokenValid: boolean;
  senha: any;
  email: any;
  rSenha: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private LoginService: LoginService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) {
    this.route.params.subscribe(res => {
      this.token = res.id

      if (this.token) {
        this.getToken();
      } 
    });
  }

  getToken(){
    this.LoginService.getToken(this.token).subscribe(data => {

      const response = (data as any);

      if(response.qtd == 1) {
        this.tokenValid = true;
        this.email = response.email;
      } else {
        this.tokenValid = false;
        this.email = null;
      }

    }, error => {
      console.error(error);
    })
  }

  recovery() {
    let arrTrocaSenha: any = {
      "email": this.email,
      "senha": this.senha,
      "token": this.token

    };
    arrTrocaSenha = JSON.stringify(arrTrocaSenha);
    this.LoginService.putTrocaSenha(arrTrocaSenha).subscribe(data => {
      if (data) {
        const response = (data as any);
        if (response.sucesso != false) {
          this.toastr.success('Senha alterada com sucesso! Você será redirecionado para a página de login...', 'Sucesso!');

          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 5000);
        } else {
            this.toastr.error('Houve um erro ao processar seu pagamento/matrícula. Confira os dados e ente novamente.', 'Erro!');
        }
        this.spinner.hide();
      }
    });
  }

  ngOnInit() {
  }

}
