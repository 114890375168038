import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../services/shared/shared.service';
import * as moment from 'moment';

@Component({
  selector: 'app-notificacoes-list',
  templateUrl: './notificacoes-list.component.html',
  styleUrls: ['./notificacoes-list.component.css']
})
export class NotificacoesListComponent implements OnInit {
  count: any;
  arr: any;
  notificacoes: any;
  id_usuario: any;

  constructor(private sharedService: SharedService) { 
    moment.locale('pt-br');
    this.id_usuario = localStorage.getItem('id_user');
  }


  
  getNotification() {

    this.sharedService.getNotificacoes(this.id_usuario).subscribe(data => {
      if (data != 'null') {
        const response = (data as any);

        // console.log(data) 
        // this.populaDados(response.anuncio[0]);

        this.count = response.count[0].count;
        this.arr = response.list;

        let i = 0;
        this.arr.forEach(obj => {

          let str = moment(obj.data).fromNow()
          str.replace("há ", "");
          this.arr[i].data = str;

          i++;

          // console.log(moment(obj.data).fromNow())

        });

        this.notificacoes = this.arr


      }
    });
  }

  ngOnInit() {
    this.getNotification();
  }

}
