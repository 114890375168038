import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CurriculoService {

  baseApiPath: any = localStorage.getItem('base_url');
  // baseApiPath: any = environment.;
  headers: any;

  constructor(public http: HttpClient) { 
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  }

  getAll() {
    return this.http.get(this.baseApiPath + '/curriculos');
  }

  getAllSelects() {
    return this.http.get(this.baseApiPath + '/carregar-selects');
  }

  getAllCidades(data) {
    return this.http.post(this.baseApiPath + '/carregar-cidades', data, this.headers);
  }

  get(id) { 
    return this.http.get(this.baseApiPath + '/curriculo/' + id);
  }

  post(data) {
    return this.http.post(this.baseApiPath + '/curriculo', data, this.headers);
  }

  check(data) {
    return this.http.post(this.baseApiPath + '/check', data, this.headers);
  }

  sendFiles(data, id) {
    return this.http.post(this.baseApiPath + '/envia-arquivos/' + id, data, this.headers);
  }

  postPesquisa(data) {
    return this.http.post(this.baseApiPath + '/curriculo-pesquisa/', data, this.headers);
  }

  postPesquisaCandidatoProcessoSeletivo(data) {
    return this.http.post(this.baseApiPath + '/pesquisa-candidato-processo-seletivo/', data, this.headers);
  }

  edit(data, id) {
    return this.http.put(this.baseApiPath + '/curriculo/' + id, data, this.headers);
  }

  delete(id) {
    return this.http.delete(this.baseApiPath + '/curriculo/' + id, this.headers);
  }
}
