import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { EventEmitterService } from '../services/emitter/event-emitter.service';
import { BrMaskDirective, BrMaskModel } from 'br-mask';
import { SharedService } from '../services/shared/shared.service';
import { UsuariosService } from '../services/usuarios/usuarios.service';


@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css'],
  providers: [DatePipe, BrMaskDirective]
})
export class PerfilComponent implements OnInit {

  id_usuario: any = localStorage.getItem("id_user");
  nm_usuario: any;
  dt_nascimento: any;
  cpf: any;
  rg: any;
  org_emissor_rg: any;
  dt_emissao_rg: any;
  rua: any;
  bairro: any;
  numero: any;
  cep: any;
  cidade: any;
  uf: any;
  status: any = 0;
  tel_fixo: any;
  tel_celular: any;
  email: any;
  senhaAntiga: any = '';
  novaSenha: any = '';
  rNovaSenha: any = '';
  customer_id: any;
  pagamentos: any;
  fileName: any;
  img: any;
  imgUrl: any;
  baseImg: string;
  perfil: any;

  constructor(
    private route: ActivatedRoute,
    public usuariosService: UsuariosService,
    private toastr: ToastrService,
    public datePipe: DatePipe,
    public brMask: BrMaskDirective,
    public sharedService: SharedService
  ) { 

    this.baseImg = localStorage.getItem('base_img');
  }

  getAluno() {
    this.usuariosService.get(this.id_usuario).subscribe(
      data => {
        const response = (data as any);

        this.nm_usuario = response[0].nm_usuario;
        this.dt_nascimento = response[0].dt_nascimento;
        this.cpf = response[0].cpf;
        this.rg = response[0].rg;
        this.org_emissor_rg = response[0].org_emissor_rg;
        this.dt_emissao_rg = response[0].dt_emissao_rg;
        this.rua = response[0].rua;
        this.numero = response[0].numero;
        this.cep = response[0].cep;
        this.bairro = response[0].bairro;
        this.cidade = response[0].cidade;
        this.uf = response[0].uf;
        this.status = response[0].status;
        this.tel_fixo = response[0].tel_fixo;
        this.tel_celular = response[0].tel_celular;
        this.email = response[0].email;
        this.perfil = response[0].perfil;
        if(response[0].img != null) {
        this.imgUrl = this.baseImg + response[0].img;
        } else {
          this.imgUrl = null;
        }
        this.customer_id = response[0].customer_id;

        // setTimeout(() => {
        //   $('table').dataTable();
        // }); 
        // this.getFinanceiro();

      }, error => {
        console.error(error);
      })
  }

  getViaCep() {
    if (confirm("Deseja auto-completar o endereço?")) {
      let tratado = this.cep.replace('.', '');
      tratado = tratado.replace('-', '');

      this.sharedService.getViaCep(tratado).subscribe(data => {
        if (data) {
          const response = (data as any);
          console.log(response);
          this.rua = response.logradouro;
          this.bairro = response.bairro;
          this.cidade = response.localidade;
          this.uf = response.uf;
        }
      })
    }
  } 

  getFinanceiro() {
    // this.usuariosService.getFinanceiro(this.customer_id).subscribe(
    //   dados => {
    //     const response = (dados as any);

    //     this.pagamentos = response.data;
    //     console.log(this.pagamentos)

    //     // setTimeout(() => {
    //     //   $('table').dataTable();
    //     // }); 

    //   }, error => {
    //     console.error(error);
    //   })
  }

  setFile(event, type) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.fileName = event.target.files[0].name;
      reader.readAsDataURL(file);

      reader.onload = () => {

        if (type == "img") {
          this.img = reader.result;
          this.imgUrl = reader.result;
        }

      };
    }
  }

  onSubmit(form) {

    let dados = form.value;
    delete dados['rNovaSenha'];

    if (this.id_usuario) {
      this.usuariosService.edit(dados, this.id_usuario).subscribe(data => {
        if (data) {
          const response = (data as any);

          this.toastr.success('Registro alterado com sucesso!', 'Sucesso!');
          window.scroll(0, 0);
          if(this.img != null) {
            EventEmitterService.get('dadosUsuario').emit("alterado");
          }
        }
      }, error => {
        console.error(error);
        this.toastr.error('Erro ao alterar registro!', 'Erro!');
      })
    } else {

      this.toastr.error('Erro ao salvar registro!', 'Erro!');

    }
  }

  ngOnInit() {
    this.getAluno();
  }

}
