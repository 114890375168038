import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CurriculoService } from 'src/app/services/curriculo/curriculo.service';
import { EventEmitterService } from 'src/app/services/emitter/event-emitter.service';
import { ProcessosSeletivosService } from 'src/app/services/processos-seletivos/processos-seletivos.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-processo-seletivo-form',
  templateUrl: './processo-seletivo-form.component.html',
  styleUrls: ['./processo-seletivo-form.component.css']
})
export class ProcessoSeletivoFormComponent implements OnInit {
  ProcessoSeletivoFormGroup: FormGroup;
  cargo: any = null;
  dt_abertura: any = null;
  andamento: any = null;
  cargos: any;
  curriculos: any = null;
  cidade: any = null;
  deficiencia: any = null;
  escolaridade: any = null;
  pretencao_salarial: any = null;
  sexo: any = null;
  uf: any = null;
  cargo_pesquisa: any = null;
  id_processo_seletivo: any = 1;

  constructor(
    private formBuilder: FormBuilder,
    private curriculoService: CurriculoService,
    private psService: ProcessosSeletivosService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private emitter: EventEmitterService,
    private route: ActivatedRoute
    ) { 
      this.route.params.subscribe(res => {
        this.id_processo_seletivo = res.id
        if (this.id_processo_seletivo) {
          this.getProcessoSeletivo();
        }
      });
    this.ProcessoSeletivoFormGroup = this.formBuilder.group({
      cargo: ['', Validators.required],
      dt_abertura: ['', Validators.required],
      andamento: ['', Validators.required],
    });
  } 


  getProcessoSeletivo() {
    this.spinner.show();
    this.psService.get(this.id_processo_seletivo).subscribe(
      data => {
        const response = (data as any);

        this.cargo = response[0].id_cargo;
        this.andamento = response[0].andamento;
        this.dt_abertura = response[0].dt_abertura;
        this.cargo_pesquisa = this.cargo;
        this.pesquisa();
        this.spinner.hide();
      }, error => {
        this.spinner.hide();
        console.error(error);
      })
  }

  getAllSelects() {
    this.curriculoService.getAllSelects().subscribe(data => {
      if (data != 'null') {
        const response = (data as any);
        this.cargos = response.cargos;
      }
    });
  }

  pesquisa() {
    this.spinner.show();

    let arr: any = {
      "cargo": this.cargo_pesquisa,
      "cidade": this.cidade,
      "deficiencia": this.deficiencia,
      "escolaridade": this.escolaridade,
      "pretencao_salarial": this.pretencao_salarial,
      "sexo": this.sexo,
      "uf": this.uf,
      "id_processo_seletivo": this.id_processo_seletivo,
    };
    arr = JSON.stringify(arr);
    console.log(arr)
    this.curriculoService.postPesquisaCandidatoProcessoSeletivo(arr).subscribe(data => {
      if (data) {
        const response = (data as any);
        if (response.sucesso != false) {
          this.curriculos = data;
          this.spinner.hide();
        } else {
          console.error(response);
          this.spinner.hide();
        }
      }
    });
  }

  vincular(id_curriculo, nm_candidato) {
    Swal.fire({
      title: 'Vincular candidato',
      text: "Deseja inserir o candidato " + nm_candidato + " neste este processo seletivo?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Não',
      confirmButtonText: 'Sim!'
    }).then((result) => {
      if (result.isConfirmed) {
        let arr: any = {
          "id_processo_seletivo": this.id_processo_seletivo,
          "id_curriculo": id_curriculo,
          "id_fase": 1,
          "pos": 0,
        };
        arr = JSON.stringify(arr);
        this.psService.postVincular(arr).subscribe(data => {
          if (data) {
            const response = (data as any);
            console.log(response);
            this.toastr.success('Candidato inserido com sucesso!', 'Sucesso!');
            this.pesquisa();
            EventEmitterService.get('novoVinculo').emit("true");
          }
        })
      }
    })
  } 

  limparFiltros() {
      this.cargo_pesquisa = null;
      this.cidade = null;
      this.deficiencia = null;
      this.escolaridade = null;
      this.pretencao_salarial = null;
      this.sexo = null;
      this.uf = null;
      this.curriculos = null;
  }

  back() {
    
  }

  save() {
    
  }

  ngOnInit() {
    this.getAllSelects();
  }

}
