import { Component, OnInit } from '@angular/core';
// import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
// import * as pluginDataLabels from 'chartjs-plugin-datalabels';
// import { Label } from 'ng2-charts';
import { SharedService } from '../services/shared/shared.service'
// import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  qtdCurriculos: any;
  qtdUsuarios: any;
  qtdNotificacoes: any;
 

  constructor(
    private SharedService: SharedService) { 

    }

  getDashboardInfos() {
    this.SharedService.getDashboardInfos().subscribe(data => {
      const response = (data as any);

      this.qtdCurriculos = response[0].qtdCurriculos;
      this.qtdUsuarios = response[0].qtdUsuarios;
      this.qtdNotificacoes = response[0].qtdNotificacoes;
    }, error => {
      console.error(error);
    })
  }

  ngOnInit() {
    this.getDashboardInfos();

    // localStorage.setItem('pageTitle','Dashboard');
  }

}
