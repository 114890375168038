import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from "./services/emitter/event-emitter.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'PAINEL';
  isAuthenticated: any = false;
  sub: any;
  environment: any;
  href: any;
  exibeTema: any = false;

  constructor(
    private route: Router
    ) {
    if (window.location.host == "localhost:4200" || window.location.host == "localhost:4201") {
      this.environment = "dev";
    } else {
      this.environment = "prod";
    }
  }

  ngOnInit() {

    let urlAtual: any = window.location.href;
    urlAtual = urlAtual.split('/')
    if(urlAtual.indexOf("curriculo-site") > -1) {
      this.exibeTema = false;
    } else { 
      this.exibeTema = true;
    }

    this.sub = EventEmitterService.get('isAuthenticated').subscribe(data => {
      localStorage.setItem('isAuthenticated', data);
      this.isAuthenticated = localStorage.getItem('isAuthenticated');

    });

    this.isAuthenticated = localStorage.getItem('isAuthenticated');

    if (this.environment == "dev") {
      localStorage.setItem('base_img', 'http://localhost/curriculo/api/public/img/');
      localStorage.setItem('base_file', 'http://localhost/curriculo/api/public/anexos/');
      localStorage.setItem('base_url', 'http://localhost/curriculo/api');
    } else if (this.environment == "prod") {
      localStorage.setItem('base_img', 'https://prod.myclick.com.br/grupohumana/api/public/img/');
      localStorage.setItem('base_file', 'https://prod.myclick.com.br/grupohumana/api/public/anexos/');
      localStorage.setItem('base_url', 'https://prod.myclick.com.br/grupohumana/api');
      
    } else if (this.environment == "homolog") {
      localStorage.setItem('base_img', 'https://homolog.myclick.com.br/curriculo/api/public/img/');
      localStorage.setItem('base_file', 'https://homolog.myclick.com.br/curriculo/api/public/anexos/');
      localStorage.setItem('base_url', 'https://homolog.myclick.com.br/curriculo/api');
    }

  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
