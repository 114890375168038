import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  baseApiPath: any = localStorage.getItem('base_url');
  headers: any;

  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  }

  getDashboardInfos() {
    return this.http.get(this.baseApiPath + '/dashboard');
  }

  getNotificacoes(id) {
    return this.http.get(this.baseApiPath + '/notificacoes/'+id); 
  }

  getNotificacao(idNotificacao) {
    return this.http.get(this.baseApiPath + '/notificacao/'+idNotificacao);
  }

  putVistoNotificacao(idNotificacao) {
    return this.http.put(this.baseApiPath + '/notificacao/'+idNotificacao, this.headers);
  }

  getAllUsuarios() {
    return this.http.get(this.baseApiPath + '/usuarios');
  }

  editNotificacao(data, id) {
    return this.http.put(this.baseApiPath + '/notificacao/' + id, data, this.headers);
  }

  newNotificacao(data) {
    return this.http.post(this.baseApiPath + '/notificacao/', data, this.headers);
  }

  getViaCep(cep) {
    return this.http.get("https://viacep.com.br/ws/"+cep+"/json/");
  }

}
