import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { CurriculoService } from 'src/app/services/curriculo/curriculo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-printpage',
  templateUrl: './printpage.component.html',
  styleUrls: ['./printpage.component.css']
})
export class PrintpageComponent implements OnInit {

  idCurriculo: any;
  estado_civil: any = null;
  sexo: any = null;
  nm_candidato: any;
  dt_nascimento: any;
  nm_mae: any;
  nm_pai: any;
  // nm_img: any;
  deficiencia: any = null;
  pretencao_salarial: any = null;
  cargo: any = null;
  nm_cargo: any;
  turno: any = null;
  baseImg: string;
  observacoes: any;
  cargos: any;
  tel_residencial: any;
  tel_celular: any;
  email: any;
  cep: any;
  tel_recados: any;
  rua: any;
  numero: any;
  uf: any = null;
  cidade: any = null;
  cpf: any;
  rg: any;
  orgao_expeditor: any;
  cnh: any;
  ultima_empresa: any;
  atividade_funcao: any = null;
  nm_chefe: any;
  tel_chefe: any;
  dt_admissao: any;
  dt_demissao: any;
  motivo_demissao: any = null;
  experiencia_profissional: any;
  ultima_empresa2: any;
  atividade_funcao2: any = null;
  nm_chefe2: any;
  tel_chefe2: any;
  dt_admissao2: any;
  dt_demissao2: any;
  motivo_demissao2: any = null;
  experiencia_profissional2: any;
  escolaridade: any = null;
  cursos: any;
  cursos_formacao: any = null;
  alfabetizado: any = 'sim';
  cid_deficiencia: any;
  idade: any;
  formacao: any;
  nm_formacao: any;
  dt_cadastro: any;
  semestre: any;
  servico_militar: any;
  regiao_servico_militar: any;
  num_dispensa: any;
  nm_cargo3: any;
  nm_cargo2: any;
  situacao: any;
  turno_faculdade: any;
  // nm_anexo: any;

  constructor(
    private route: ActivatedRoute,
    private _location: Location,
    private curriculoService: CurriculoService,
    private spinner: NgxSpinnerService
    ) {
      this.route.params.subscribe(res => {
      this.idCurriculo = res.id
      if (this.idCurriculo) {
        this.getCurriculo();
      }
    });  }



    getCurriculo() {
      this.spinner.show();
      this.curriculoService.get(this.idCurriculo).subscribe(
        data => {
          const response = (data as any);
  
          this.dt_cadastro = response[0].dt_cadastro;

          this.nm_candidato = response[0].nm_candidato;
          this.sexo = response[0].sexo;
          this.estado_civil = response[0].estado_civil;
          this.dt_nascimento = response[0].dt_nascimento;
          this.idade = this.calcAge(response[0].dt_nascimento);
          this.nm_mae = response[0].nm_mae;
          this.nm_pai = response[0].nm_pai;
          // this.nm_img = response[0].nm_img;
  
          this.deficiencia = response[0].deficiencia;
          this.cid_deficiencia = response[0].cid_deficiencia;
          this.pretencao_salarial = response[0].pretencao_salarial;
          this.cargo = response[0].cargo;
          this.nm_cargo = response[0].nm_cargo;
          this.nm_cargo2 = response[0].nm_cargo2;
          this.nm_cargo3 = response[0].nm_cargo3;
          this.turno = response[0].turno;
          this.pretencao_salarial = response[0].pretencao_salarial;
          this.observacoes = response[0].observacoes;
  
          this.tel_residencial = response[0].tel_residencial;
          this.tel_celular = response[0].tel_celular;
          this.tel_recados = response[0].tel_recados;
          this.email = response[0].email;
          this.cep = response[0].cep;
          this.rua = response[0].rua;
          this.numero = response[0].numero;
          this.uf = response[0].uf;
          this.cidade = response[0].cidade;
  
          this.cpf = response[0].cpf;
          this.rg = response[0].rg;
          this.orgao_expeditor = response[0].orgao_expeditor;
          this.cnh = response[0].cnh;
          this.servico_militar = response[0].servico_militar;
          this.regiao_servico_militar = response[0].regiao_servico_militar;
          this.num_dispensa = response[0].num_dispensa;
  
          this.ultima_empresa = response[0].ultima_empresa;
          this.atividade_funcao = response[0].atividade_funcao;
          this.nm_chefe = response[0].nm_chefe;
          this.tel_chefe = response[0].tel_chefe;
          this.dt_admissao = response[0].dt_admissao;
          this.dt_demissao = response[0].dt_demissao;
          this.motivo_demissao = response[0].motivo_demissao;
          this.experiencia_profissional = response[0].experiencia_profissional;

          this.ultima_empresa2 = response[0].ultima_empresa2;
          this.atividade_funcao2 = response[0].atividade_funcao2;
          this.nm_chefe2 = response[0].nm_chefe2;
          this.tel_chefe2 = response[0].tel_chefe2;
          this.dt_admissao2 = response[0].dt_admissao2;
          this.dt_demissao2 = response[0].dt_demissao2;
          this.motivo_demissao2 = response[0].motivo_demissao2;
          this.experiencia_profissional2 = response[0].experiencia_profissional2;
  
          this.escolaridade = response[0].escolaridade;
          this.alfabetizado = response[0].alfabetizado;
          this.nm_formacao = response[0].nm_formacao;
          this.cursos = response[0].cursos;
          this.semestre = response[0].semestre;
          this.situacao = response[0].situacao;
          this.turno_faculdade = response[0].turno_faculdade;

          this.spinner.hide();
        }, error => {
          console.error(error);
        })
    }

    calcAge(dateString) {
      var birthday = +new Date(dateString);
      return ~~((Date.now() - birthday) / (31557600000));
    }

  print() {
    window.print();
  }

  back() {
    this._location.back();
  }

  ngOnInit() {
    this.getCurriculo();
  }

}
