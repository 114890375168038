import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './error404/error404.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './components/shared/login/login.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { NotificacoesListComponent } from './notificacoes/notificacoes-list/notificacoes-list.component';
import { NotificacaoViewComponent } from './notificacoes/notificacao-view/notificacao-view.component';
import { RecuperarSenhaComponent } from './components/shared/recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './components/shared/nova-senha/nova-senha.component';
import { NotificacoesFormComponent } from './notificacoes/notificacoes-form/notificacoes-form.component';
import { PerfilComponent } from './perfil/perfil.component';
import { CandeactivateService } from './services/candeactivate/candeactivate.service';
import { CurriculoListComponent } from './curriculo/curriculo-list/curriculo-list.component';
import { CurriculoFormComponent } from './curriculo/curriculo-form/curriculo-form.component';
import { PrintpageComponent } from './curriculo/imprimir/printpage/printpage.component';
import { CurriculoSiteComponent } from './curriculo-site/curriculo-site.component';
import { UsuarioListComponent } from './usuarios/usuario-list/usuario-list.component';
import { UsuarioFormComponent } from './usuarios/usuario-form/usuario-form.component';
import { KanbanComponent } from './kanban/kanban.component';
import { ProcessoSeletivoListComponent } from './processos-seletivos/processo-seletivo-list/processo-seletivo-list.component';
import { ProcessoSeletivoFormComponent } from './processos-seletivos/processo-seletivo-form/processo-seletivo-form.component';

const routes: Routes = [ 
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'recuperar-senha', component: RecuperarSenhaComponent
  },
  {
    path: 'recuperar-senha/:id', component: NovaSenhaComponent
  },
  {
    path: 'dashboard', component: DashboardComponent,
    data: {
      breadcrumb: 'Dashboard'
    },
    canActivate: [AuthGuardService]
  },
  {
    path: 'curriculo-site', component: CurriculoSiteComponent
  },
  {
    path: 'curriculos', component: CurriculoListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'curriculo', component: CurriculoFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'curriculo/:id', component: CurriculoFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'curriculo/imprimir/:id', component: PrintpageComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'usuarios', component: UsuarioListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'usuario', component: UsuarioFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'usuario/:id', component: UsuarioFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'kanban', component: KanbanComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'processos-seletivos', component: ProcessoSeletivoListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'processo-seletivo/:id', component: ProcessoSeletivoFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'processo-seletivo', component: ProcessoSeletivoFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'notificacoes', component: NotificacoesListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'notificacoes/:id', component: NotificacaoViewComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'notificacao', component: NotificacoesFormComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'perfil', component: PerfilComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: '', redirectTo: '/dashboard', pathMatch: 'full',
    canActivate: [AuthGuardService] 
  },
  {
    path: '**', component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }