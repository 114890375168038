import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from 'src/app/services/shared/shared.service';
import { UsuariosService } from 'src/app/services/usuarios/usuarios.service';
import { DatePipe, Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-usuario-form',
  templateUrl: './usuario-form.component.html',
  styleUrls: ['./usuario-form.component.css']
})
export class UsuarioFormComponent implements OnInit {
  id_usuario: any;

  nm_usuario: any;
  dt_nascimento: any;
  cpf: any;
  rg: any;
  org_emissor_rg: any;
  dt_emissao_rg: any;
  rua: any;
  bairro: any;
  numero: any;
  cep: any;
  cidade: any;
  uf: any;
  status: boolean = null;
  tel_fixo: any;
  tel_celular: any;
  email: any;
  senha: any;
  senha_aux: any;
  email_dados: any;
  usuariosFormGroup: FormGroup;

  constructor(
    private route: ActivatedRoute,
    public usuariosService: UsuariosService,
    public sharedService: SharedService,
    private toastr: ToastrService,
    private _location: Location,
    private router: Router,
    public datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef
  ) { 
    this.route.params.subscribe(res => {
    this.id_usuario = res.id
    if (this.id_usuario) {
      this.getUsuario();
      // this.emailDados = false;
    } else {
      // this.emailDados = true;
    }
  });

  this.usuariosFormGroup = this.formBuilder.group({
    status: ['', Validators.required],
    nm_usuario: ['', Validators.required],
    cpf: ['', Validators.required],
    tel_celular: ['', Validators.required],
    email: ['', Validators.required],
    senha: ['', Validators.minLength(5)] 
  });

  if(!this.id_usuario) {
    this.usuariosFormGroup.controls["senha"].setValidators([Validators.required, Validators.minLength(5)]);
  } else {
    this.usuariosFormGroup.controls["senha"].setValidators([Validators.minLength(5)]);
  }
}

  getUsuario() {
    this.usuariosService.get(this.id_usuario).subscribe(
      data => {
        const response = (data as any);

        this.nm_usuario = response[0].nm_usuario;
        this.dt_nascimento = response[0].dt_nascimento;
        this.cpf = response[0].cpf;
        this.rg = response[0].rg;
        this.org_emissor_rg = response[0].org_emissor_rg;
        this.dt_emissao_rg = response[0].dt_emissao_rg;
        this.rua = response[0].rua;
        this.numero = response[0].numero;
        this.cep = response[0].cep;
        this.bairro = response[0].bairro;
        this.cidade = response[0].cidade;
        this.uf = response[0].uf;
        this.status = response[0].status;
        this.tel_fixo = response[0].tel_fixo;
        this.tel_celular = response[0].tel_celular;
        this.email = response[0].email;
        this.senha_aux = response[0].senha;

        // setTimeout(() => {
        //   $('table').dataTable();
        // }); 

      }, error => {
        console.error(error);
      })
  }

  getViaCep() {
    if (confirm("Deseja auto-completar o endereço?")) {
      let tratado = this.cep.replace('.', '');
      tratado = tratado.replace('-', '');

      this.sharedService.getViaCep(tratado).subscribe(data => {
        if (data) {
          const response = (data as any);
          console.log(response);
          this.rua = response.logradouro;
          this.bairro = response.bairro;
          this.cidade = response.localidade;
          this.uf = response.uf;
        }
      })
    }
  }

  gerarSenha() {
    this.senha = Math.random().toString(36).slice(-6);
  }

  onSubmit(form) {

    this.spinner.show(); 

    // let dados = form.value;

    this.spinner.show()
    let arr: any = {
      "status": this.status,
      "nm_usuario": this.nm_usuario,
      "dt_nascimento": this.dt_nascimento,
      "cpf": this.cpf,
      "rg": this.rg,
      "org_emissor_rg": this.org_emissor_rg,
      "dt_emissao_rg": this.dt_emissao_rg,
      "cep": this.cep,
      "rua": this.rua,
      "numero": this.numero,
      "bairro": this.bairro,
      "cidade": this.cidade,
      "uf": this.uf,
      "tel_fixo": this.tel_fixo,
      "tel_celular": this.tel_celular,
      "email": this.email,
      "senha": this.senha,
    };
    if(this.senha == '') {
      delete arr['senha'];
    }
    arr = JSON.stringify(arr);
    if (this.id_usuario) {
      this.usuariosService.edit(arr, this.id_usuario).subscribe(data => {
        if (data) {
          const response = (data as any);
          if(response.sucesso != false) {
          this.toastr.success('Registro alterado com sucesso!', 'Sucesso!');
          window.scroll(0, 0);
        } else {
            this.spinner.hide();
            this.toastr.error('Erro ao salvar registro!', 'Erro!');
            console.error(response);
          }
        }
          this.spinner.hide();
      }, error => {
        console.error(error);
        this.spinner.hide();
        this.toastr.error('Erro ao alterar registro!', 'Erro!');
      })
    } else {
      this.usuariosService.post(arr).subscribe(data => {
        if (data) {
          const response = (data as any);
          if(response.sucesso != false) {
          this.toastr.success('Registro salvo com sucesso!', 'Sucesso!');
          window.scroll(0, 0);
          this.spinner.hide();
          // console.log(response);
          this.router.navigate(['/tutor/' + response]);
          } else {
            this.spinner.hide();
            this.toastr.error('Erro ao salvar registro!', 'Erro!');
            console.error(response);
          }
        }
      }, error => {
        console.error(error);
        this.spinner.hide();
        this.toastr.error('Erro ao salvar registro!', 'Erro!');
      })
    }
  }

  back() {
    this._location.back();
  }

  ngOnInit() {
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

}
