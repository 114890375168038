import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from 'src/app/services/emitter/event-emitter.service';
// import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html'
})
export class MenuComponent implements OnInit {

  nmUser: any;
  id_usuario: any;
  level_access: any;
  baseImg: string;
  qtd_aulas: any;
  imgUsuario: any;
  sub: any;

  constructor(
    private router: Router,
    // private toastr: ToastrService
  ) {
    this.id_usuario = localStorage.getItem('id_user');
    this.level_access = localStorage.getItem('level_access'); 
    this.baseImg = localStorage.getItem('base_img');
  }

  getDadosMenu() {

    this.id_usuario = localStorage.getItem("id_user");
    this.imgUsuario = localStorage.getItem("user_img");
    // this.cursosService.getDadosMenu(this.id_usuario).subscribe(
    //   data => {
    //     const response = (data as any);
    //     this.qtd_aulas = response[0].qtd_cursos;
    //     this.imgUsuario = response[0].img;
    //     localStorage.setItem('user_img', response[0].img);
    //   }, error => {
    //     console.error(error);
    //   })
  }

  logout() {

    localStorage.setItem('id_user', null);
    localStorage.setItem('nm_user', null);
    localStorage.setItem('level_access', null);
    localStorage.setItem('isAuthenticated', null);
    
    EventEmitterService.get('isAuthenticated').emit("login");

    // this.toastr.info('Você foi deslogado com sucesso!', 'Informação!');
    this.router.navigate(['/login']);
  }

  atualizaDadosMenu() {
    this.sub = EventEmitterService.get('dadosUsuario').subscribe(data => {
      if(data == "alterado") {
        this.getDadosMenu();
      }
    });
  }

  ngOnInit() {
    this.nmUser = localStorage.getItem('nm_user');
    this.getDadosMenu();
    this.atualizaDadosMenu();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
