import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-notificacoes-form',
  templateUrl: './notificacoes-form.component.html',
  styleUrls: ['./notificacoes-form.component.css']
})
export class NotificacoesFormComponent implements OnInit {

  usuario: any = null; 
  icone: any = "fas fa-envelope";
  usuarios: any;
  idNotificacao: any;
  texto: any;

  constructor(
    private sharedService: SharedService,
    private route: ActivatedRoute, 
    private _location: Location,
    private toastr: ToastrService
    ) { }



    getAllUsuarios() {
    this.sharedService.getAllUsuarios().subscribe(data => {
      if (data != 'null') {
        const response = (data as any);

        // console.log(data) 
        // this.populaDados(response.anuncio[0]);

        this.usuarios = response;
      }
    });
  }

  onSubmit(form) {

    // console.log(form.value) 

    let dados = form.value;

    if (this.idNotificacao) {
      this.sharedService.editNotificacao(dados, this.idNotificacao).subscribe(data => {
          if (data) {
            const response = (data as any);

            this.toastr.success('Registro alterado com sucesso!', 'Sucesso!');
            window.scroll(0, 0);
          }
        }, error => {
          console.error(error);
          this.toastr.error('Erro ao alterar registro!', 'Erro!');
        })
    } else {
      this.sharedService.newNotificacao(dados).subscribe(data => {
          if (data) {
            const response = (data as any);

            this.toastr.success('Registro salvo com sucesso!', 'Sucesso!');
            window.scroll(0, 0);
          }
        }, error => {
          console.error(error);
          this.toastr.error('Erro ao salvar registro!', 'Erro!');
        })
    }
  }

  back() {
    this._location.back();
  }

  ngOnInit() {
    this.getAllUsuarios();
  }

}
