import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CurriculoService } from '../services/curriculo/curriculo.service';
import { SharedService } from '../services/shared/shared.service';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import { isNumber } from 'util';

@Component({
  selector: 'app-curriculo-site',
  templateUrl: './curriculo-site.component.html',
  styleUrls: ['./curriculo-site.component.css']
})
export class CurriculoSiteComponent implements OnInit {

  estado_civil: any = null;
  sexo: any = null;
  nm_candidato: any;
  dt_nascimento: any;
  nm_mae: any;
  nm_pai: any;
  nm_img: any;
  deficiencia: any = null;
  pretencao_salarial: any = null;
  cargo: any = null;
  cargo2: any = null;
  cargo3: any = null;
  turno: any = null;
  baseImg: string;
  observacoes: any;
  cargos: any;
  tel_residencial: any;
  tel_celular: any;
  email: any;
  cep: any;
  tel_recados: any;
  rua: any;
  numero: any;
  uf: any = null;
  cidade: any = null;
  outra_cidade: any;
  cpf: any;
  rg: any;
  orgao_expeditor: any;
  cnh: any;
  ultima_empresa: any = '';
  atividade_funcao: any = null;
  nm_chefe: any;
  tel_chefe: any;
  dt_admissao: any;
  dt_demissao: any;
  motivo_demissao: any = null;
  experiencia_profissional: any;
  ultima_empresa2: any;
  atividade_funcao2: any = null;
  nm_chefe2: any;
  tel_chefe2: any;
  dt_admissao2: any;
  dt_demissao2: any;
  motivo_demissao2: any = null;
  experiencia_profissional2: any;
  escolaridade: any = null;
  cursos: any;
  formacao: any = null;
  cursos_formacao: any = null;
  semestre: any = null;
  turno_faculdade: any = 'Matutino';
  situacao: any = 'Cursando';
  alfabetizado: any = 'sim';
  cid_deficiencia: any;
  nm_anexo: any;
  fileNameFoto: any;
  img: string | ArrayBuffer;
  cidades: any;
  DadosPessoaisFormGroup: FormGroup;
  DadosProfissionaisFormGroup: FormGroup;
  DocumentosFormGroup: FormGroup;
  ContatoFormGroup: FormGroup;
  ExperienciaFormGroup: FormGroup;
  EducacaoFormGroup: FormGroup;
  anexo_curriculo: string | ArrayBuffer;
  fileNameCurriculo: any;
  extensaoFoto: any;
  extensaoCurriculo: any;
  servico_militar: any;
  regiao_servico_militar: any;
  num_dispensa: any;

  constructor(
    private curriculoService: CurriculoService,
    private spinner: NgxSpinnerService,
    public sharedService: SharedService,
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef
  ) {
    this.DadosPessoaisFormGroup = this.formBuilder.group({
      nm_candidato: ['', Validators.required],
      sexo: ['', Validators.required],
      estado_civil: ['', Validators.required],
      dt_nascimento: ['', Validators.required],
      nm_mae: ['', Validators.required],
    });
    this.DadosProfissionaisFormGroup = this.formBuilder.group({
      deficiencia: ['', Validators.required],
      pretencao_salarial: ['', Validators.required],
      cargo: ['', Validators.required],
    });
    this.DocumentosFormGroup = this.formBuilder.group({
      cpf: ['', Validators.required],
      rg: ['', Validators.required],
      orgao_expeditor: ['', Validators.required],
    });
    this.ContatoFormGroup = this.formBuilder.group({
      tel_celular: ['', Validators.required],
      email: ['', Validators.required],
      rua: ['', Validators.required],
      numero: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: [''],
      outra_cidade: ['']
    });
    this.ExperienciaFormGroup = this.formBuilder.group({
      atividade_funcao: [null],
      dt_admissao: [''],
      motivo_demissao: ['']
    });
    this.EducacaoFormGroup = this.formBuilder.group({
      escolaridade: ['', Validators.required],
      formacao: [''],
      semestre: [''],
      situacao: [''],
    });
  }

  changeValidators() {
    if (this.escolaridade == 5) {
      this.EducacaoFormGroup = this.formBuilder.group({
        escolaridade: [this.escolaridade, Validators.required],
        formacao: [this.formacao, Validators.required],
        semestre: [this.semestre, Validators.required],
        situacao: [this.situacao, Validators.required],
        turno_faculdade: [this.turno_faculdade, Validators.required],
      });
    } else if (this.escolaridade == 6) {
      this.EducacaoFormGroup = this.formBuilder.group({
        escolaridade: [this.escolaridade, Validators.required],
        formacao: [this.formacao, Validators.required],
        semestre: [this.semestre, null],
        situacao: [this.situacao, null],
        turno_faculdade: [this.turno_faculdade, null],
      });
    } else {
      this.EducacaoFormGroup = this.formBuilder.group({
        escolaridade: [this.escolaridade, Validators.required],
        formacao: [null, null],
        semestre: [null, null],
        situacao: [null, null],
        turno_faculdade: [null, null],
      });
    }
    if (this.ultima_empresa.length > 0) {
      this.ExperienciaFormGroup = this.formBuilder.group({
        atividade_funcao: [this.atividade_funcao, Validators.required],
        dt_admissao: [this.dt_admissao, Validators.required],
        motivo_demissao: [this.motivo_demissao, Validators.required]
      });
    } else {
      this.ExperienciaFormGroup = this.formBuilder.group({
        atividade_funcao: [null, null],
        dt_admissao: ['', null],
        dt_demissao: ['', null],
        motivo_demissao: [null, null]
      });
    }
    if (this.uf == 'DF' || this.uf == 'GO') {
      this.ContatoFormGroup = this.formBuilder.group({
        tel_celular: [this.tel_celular, Validators.required],
        email: [this.email, Validators.required],
        rua: [this.rua, Validators.required],
        numero: [this.numero, Validators.required],
        uf: [this.uf, Validators.required],
        cidade: [this.cidade, Validators.required],
        outra_cidade: [this.outra_cidade, null]
      });
    } else {
      this.ContatoFormGroup = this.formBuilder.group({
        tel_celular: [this.tel_celular, Validators.required],
        email: [this.email, Validators.required],
        rua: [this.rua, Validators.required],
        numero: [this.numero, Validators.required],
        uf: [this.uf, Validators.required],
        cidade: [this.cidade, null],
        outra_cidade: [this.outra_cidade, null] 
      });
    }
  }

  getAllSelects() {
    this.curriculoService.getAllSelects().subscribe(data => {
      if (data != 'null') {
        const response = (data as any);
        this.cargos = response.cargos;
        this.cursos_formacao = response.cursos;
      }
    });
  }

  getCidades() {
    let arr: any = {
      "uf": this.uf
    };
    arr = JSON.stringify(arr);
    this.curriculoService.getAllCidades(arr).subscribe(data => {
      const response = (data as any);
      this.cidade = null;
      this.cidades = response;
    });
    this.changeValidators();
  }

  // getViaCep() {
  //   Swal.fire({
  //     title: 'Endereço',
  //     text: "Deseja auto-completar o endereço?",
  //     icon: 'warning',
  //     showCancelButton: true,
  //     confirmButtonColor: '#3085d6',
  //     cancelButtonColor: '#d33',
  //     cancelButtonText: 'Não',
  //     confirmButtonText: 'Sim!',
  //     target: document.getElementById('Containerg4csn')
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       let tratado = this.cep.replace('.', '');
  //       tratado = tratado.replace('-', '');
  //       this.sharedService.getViaCep(tratado).subscribe(data => {
  //         if (data) {
  //           const response = (data as any);
  //           console.log(response);
  //           this.rua = response.logradouro;
  //           this.cidade = null;
  //           this.uf = response.uf;
  //         }
  //       })
  //     }
  //   })
  // } 

  getViaCep() {

    let r = confirm("Deseja auto-completar o endereço?");
    if (r == true) {
      let tratado = this.cep.replace('.', '');
        tratado = tratado.replace('-', '');
        this.sharedService.getViaCep(tratado).subscribe(data => {
          if (data) {
            const response = (data as any);
            console.log(response);
            this.rua = response.logradouro;
            this.cidade = null;
            this.uf = response.uf;
          }
        })
    }
  }

  setFoto(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let fileFoto = event.target.files[0];
      this.fileNameFoto = event.target.files[0].name;
      this.extensaoFoto = this.fileNameFoto.split('.').pop();
      console.log(this.extensaoFoto)
      reader.readAsDataURL(fileFoto);

      reader.onload = () => {
        this.img = reader.result;
      };
    }
  }

  setAnexoCurriculo(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      this.fileNameCurriculo = event.target.files[0].name;
      this.extensaoCurriculo = this.fileNameCurriculo.split('.').pop();
      console.log(this.extensaoCurriculo)
      reader.readAsDataURL(file);

      reader.onload = () => {
        this.anexo_curriculo = reader.result;
      };
    }
  }

  check() {
    this.spinner.show()
    let arr: any = {
      "cpf": this.cpf
    };
    arr = JSON.stringify(arr);
    this.curriculoService.check(arr).subscribe(data => {
      const response = (data as any);
      console.log(response);
      if (response.qtd_curriculos != 0) {
        Swal.fire({
          title: 'Atenção!',
          text: "Este CPF já encontra-se cadastrado em nosso sistema. Deseja apagar o antigo e criar este novo?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          cancelButtonText: 'Não',
          confirmButtonText: 'Sim!',
          target: document.getElementById('Containerg4csn')
        }).then((result) => {
          if (result.isConfirmed) {
            this.onSubmit();
          }
        })
      } else {
        this.onSubmit();
      }
      this.spinner.hide()
    }, error => {
      console.error(error);
      this.spinner.hide()
    });
  }

  onSubmit() {
    this.spinner.show()
    this.dt_admissao == '' ? null : this.dt_admissao;
    this.dt_demissao == '' ? null : this.dt_demissao;
    this.dt_admissao2 == '' ? null : this.dt_admissao2;
    this.dt_demissao2 == '' ? null : this.dt_demissao2;

    let arr: any = {
      "nm_candidato": this.nm_candidato,
      "sexo": this.sexo,
      "estado_civil": this.estado_civil,
      "dt_nascimento": this.dt_nascimento,
      "nm_mae": this.nm_mae,
      "nm_pai": this.nm_pai,
      "deficiencia": this.deficiencia,
      "cid_deficiencia": this.cid_deficiencia,
      "pretencao_salarial": this.pretencao_salarial,
      "cargo": this.cargo,
      "cargo2": this.cargo2,
      "cargo3": this.cargo3,
      "cpf": this.cpf,
      "rg": this.rg,
      "orgao_expeditor": this.orgao_expeditor,
      "cnh": this.cnh,
      "servico_militar": this.servico_militar,
      "regiao_servico_militar": this.regiao_servico_militar,
      "num_dispensa": this.num_dispensa,
      "tel_residencial": this.tel_residencial,
      "tel_celular": this.tel_celular,
      "tel_recados": this.tel_recados,
      "email": this.email,
      "cep": this.cep,
      "rua": this.rua,
      "numero": this.numero,
      "uf": this.uf,
      "cidade": this.cidade,
      "ultima_empresa": this.ultima_empresa,
      "atividade_funcao": this.atividade_funcao,
      "nm_chefe": this.nm_chefe,
      "tel_chefe": this.tel_chefe,
      "dt_admissao": this.dt_admissao,
      "dt_demissao": this.dt_demissao,
      "motivo_demissao": this.motivo_demissao,
      "experiencia_profissional": this.experiencia_profissional,
      "ultima_empresa2": this.ultima_empresa2,
      "atividade_funcao2": this.atividade_funcao2,
      "nm_chefe2": this.nm_chefe2,
      "tel_chefe2": this.tel_chefe2,
      "dt_admissao2": this.dt_admissao2,
      "dt_demissao2": this.dt_demissao2,
      "motivo_demissao2": this.motivo_demissao2,
      "experiencia_profissional2": this.experiencia_profissional2,
      "escolaridade": this.escolaridade,
      "alfabetizado": this.alfabetizado,
      "formacao": this.formacao,
      "semestre": this.semestre,
      "situacao": this.situacao,
      "turno_faculdade": this.turno_faculdade,
      "cursos": this.cursos,
      "turno": this.turno,
      "observacoes": this.observacoes,
    };
    arr = JSON.stringify(arr);
    this.curriculoService.post(arr).subscribe(data => {
      const response = (data as any);
      console.log(response);
      if (response.sucesso == false) {
        this.errorMsg();
        console.error(response)
      } else {
        this.successMsg();
        this.sendNotification();

        if (this.img) {
          this.sendFiles(response.id_curriculo, this.img, this.extensaoFoto, 'img');
        }

        if (this.anexo_curriculo) {
          this.sendFiles(response.id_curriculo, this.anexo_curriculo, this.extensaoCurriculo, 'anexo');
        }
      }
      this.spinner.hide()
    }, error => {
      console.error(error);
      this.spinner.hide()
    });
  }

  sendFiles(id, anexoB64, extensao, tipo) {
    this.spinner.show()
    let arr: any = {
      "id_curriculo": id,
      "anexoB64": anexoB64,
      "extensao": extensao,
      "tipo": tipo
    };
    arr = JSON.stringify(arr);
    this.curriculoService.sendFiles(arr, id).subscribe(data => {
      const response = (data as any);
      console.log(response);
      this.spinner.hide()
    }, error => {
      console.error(error);
      this.spinner.hide()
    });

  }

  successMsg() {
    Swal.fire({
      icon: 'success',
      title: 'Sucesso!',
      text: 'Seu currículo foi cadastrado com sucesso em nosso sistema.',
      footer: 'Em breve nossa equipe entrará em contato com você, boa sorte!',
      target: document.getElementById('Containerg4csn'),
      position: 'bottom'
    })
  }

  errorMsg() {
    Swal.fire({
      icon: 'error',
      title: 'Erro!',
      text: 'Houve um erro ao cadastrar o seu currículo...',
      footer: 'Por favor, verifique se seus dados estão corretos!',
      position: 'bottom'
    })
  }

  sendNotification() {
    console.log('sendNotification init')
    let arr: any = {
      "icone": 'fas fa-user-tie',
      "id_usuario": null,
      "status": 0,
      "texto": 'Novo currículo: ' + this.nm_candidato,
    };
    arr = JSON.stringify(arr);
    this.sharedService.newNotificacao(arr).subscribe(data => {
      if (data) {
        const response = (data as any);
        console.log('Notificação enviada');
      }
    }, error => {
      console.error(error);
    })
  }

  ngOnInit() {
    this.getAllSelects();
  }

  ngOnDestroy() {
    this.getAllSelects();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

}
