import { Component, OnInit } from '@angular/core';
import { LoginService } from '../../../services/login/login.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-recuperar-senha',
  templateUrl: './recuperar-senha.component.html',
  styleUrls: ['./recuperar-senha.component.css']
})
export class RecuperarSenhaComponent implements OnInit {

  email: string;

  constructor(
    private router: Router,
    private LoginService: LoginService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
    ) { }

  
    onSubmit(form) {
      this.spinner.show();
 
      let arrRecuperaSenha: any = {
        "email": this.email,
        "origem": "painel"
      };
      arrRecuperaSenha = JSON.stringify(arrRecuperaSenha);
      this.LoginService.recuperarSenha(arrRecuperaSenha).subscribe(data => {
        if (data) {
          const response = (data as any);
  
          console.log(response.result);
          if (response.result == true) {
            this.toastr.success('Em alguns minutos será enviado para seu e-mail as instruções para prosseguir com a recuperação de sua senha.', 'Sucesso!');
            this.spinner.hide();
            // this.router.navigate(['/dashboard']);
  
          } else if (response.result == false) {
            this.toastr.error('Este email não encontra-se cadastrado em nossa base de dados!', 'Erro!');
            this.spinner.hide();
          }
  
  
        }
      }, error => {
        console.error(error);
        this.toastr.error('Houve um erro na conexão com o servidor: ' + error, 'Erro!');
        this.spinner.hide();
      })
    }

  ngOnInit() {
  }

}
