import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UsuariosService {

  baseApiPath: any = localStorage.getItem('base_url');
  headers: any;

  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded'); 
  }

  getAll() {
    return this.http.get(this.baseApiPath + '/usuarios'); 
  }

  get(id) { 
    return this.http.get(this.baseApiPath + '/usuario/' + id);
  }

  post(data) {
    return this.http.post(this.baseApiPath + '/usuario', data, this.headers);
  }

  edit(data, id) {
    return this.http.put(this.baseApiPath + '/usuario/' + id, data, this.headers);
  }

  delete(id) {
    return this.http.delete(this.baseApiPath + '/usuario/' + id, this.headers);
  }

}
