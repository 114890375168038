import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmitterService } from "../../../services/emitter/event-emitter.service";

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html'
})
export class ContentComponent implements OnInit {
  pageTitle: string;
  isAuthenticated: any = false;
  sub: any;
  exibeTema: boolean;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {

    let urlAtual: any = window.location.href;
    urlAtual = urlAtual.split('/')
    if(urlAtual.indexOf("curriculo-site") > -1) {
      this.exibeTema = false;
    } else { 
      this.exibeTema = true;
    }

    this.sub = EventEmitterService.get('isAuthenticated').subscribe(data => {
      localStorage.setItem('isAuthenticated', data);
      this.isAuthenticated = localStorage.getItem('isAuthenticated');

    });

    this.isAuthenticated = localStorage.getItem('isAuthenticated');
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
