import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { EventEmitterService } from "../../services/emitter/event-emitter.service";
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-notificacao-view',
  templateUrl: './notificacao-view.component.html',
  styleUrls: ['./notificacao-view.component.css']
})
export class NotificacaoViewComponent implements OnInit {
  id: any;
  notificacoes: any;

  constructor(
    private sharedService: SharedService,
    private route: ActivatedRoute,
    public datePipe: DatePipe
  ) {

    this.route.params.subscribe(res => {
      this.id = res.id
      if (this.id) {
        this.getNotificacao();
        this.putVistoNotificacao();
      }
    });
  }

  getNotificacao() {
    this.sharedService.getNotificacao(this.id).subscribe(
      data => {
        const response = (data as any);
        this.notificacoes = response;
      }, error => {
        console.error(error);
      })
  }

  putVistoNotificacao() {
    this.sharedService.putVistoNotificacao(this.id).subscribe(
      data => {
        EventEmitterService.get('reloadNotification').emit("reload");
      }, error => {
        console.error(error);
      })
  }

  ngOnInit() {
  }

}
