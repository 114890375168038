import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  login: any;
  private isAuthenticated: boolean = false;

  constructor(private router: Router) { }

  canActivate() {
    this.login = localStorage.getItem('isAuthenticated');
    // alert(this.login);
    if (this.login == "true") {
      this.isAuthenticated = true;
    } else if(this.login != "true") {
      this.isAuthenticated = false;
      this.router.navigate(['/login']);
    } 

    return this.isAuthenticated;
  }
}