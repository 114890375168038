import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventEmitterService } from "../../../services/emitter/event-emitter.service";
import { LoginService } from '../../../services/login/login.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isAuthenticated: any = localStorage.getItem('isAuthenticated');
  email: any;
  senha: any;

  constructor(
    private router: Router,
    private LoginService: LoginService,
    private toastr: ToastrService
  ) { }

  checkValue(event: any){
      localStorage.setItem('remember_login', event);
 }

  onSubmit(form) {
    let dados = form.value;
    // console.log(dados);

    this.LoginService.login(dados).subscribe(data => {
      if (data) {
        const response = (data as any);

        console.log(response);
        if (response.id_usuario) {
          this.toastr.success('Login efetuado com sucesso!', 'Sucesso!');

          localStorage.setItem('id_user', response.id_usuario);
          localStorage.setItem('nm_user', response.nm_usuario);
          localStorage.setItem('level_access', response.perfil);
          localStorage.setItem('user_img', response.img);

          EventEmitterService.get('isAuthenticated').emit("true");
          this.router.navigate(['/dashboard']);

        } else {
          this.toastr.error('Usuário ou senha errado(s)!', 'Erro!');
        }


      }
    }, error => {
      console.error(error);
      this.toastr.error('Houve um erro na conexão com o servidor: ' + error, 'Erro!'); 
    })
  }

  recovery() {
    EventEmitterService.get('isAuthenticated').emit("recovery");
    this.router.navigate(['/recuperar-senha']);
  }

  ngOnInit() {
    if(this.isAuthenticated == "true") {
      this.router.navigate(['/dashboard']);
    }
  }

}
