import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { CurriculoService } from 'src/app/services/curriculo/curriculo.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-curriculo-form',
  templateUrl: './curriculo-form.component.html',
  styleUrls: ['./curriculo-form.component.css']
})
export class CurriculoFormComponent implements OnInit {
  idCurriculo: any;
  estado_civil: any = null;
  sexo: any = null;
  nm_candidato: any;
  dt_nascimento: any;
  nm_mae: any;
  nm_pai: any;
  nm_img: any;
  deficiencia: any = null;
  pretencao_salarial: any = null;
  cargo: any = null;
  cargo2: any = '';
  cargo3: any = '';
  turno: any = null;
  baseImg: string;
  observacoes: any;
  cargos: any;
  tel_residencial: any;
  tel_celular: any;
  email: any;
  cep: any;
  tel_recados: any;
  rua: any;
  numero: any;
  uf: any = null;
  cidade: any = null;
  cpf: any;
  rg: any;
  orgao_expeditor: any;
  cnh: any;
  servico_militar: any;
  ultima_empresa: any;
  atividade_funcao: any = null;
  nm_chefe: any;
  tel_chefe: any;
  dt_admissao: any;
  dt_demissao: any;
  motivo_demissao: any = null;
  experiencia_profissional: any;
  ultima_empresa2: any;
  atividade_funcao2: any = null;
  nm_chefe2: any;
  tel_chefe2: any;
  dt_admissao2: any;
  dt_demissao2: any;
  motivo_demissao2: any = null;
  experiencia_profissional2: any;
  escolaridade: any = null;
  cursos: any;
  cursos_formacao: any = null;
  semestre: any = null;
  turno_faculdade: any = 'Matutino';
  situacao: any = 'Cursando';
  alfabetizado: any = 'sim';
  cid_deficiencia: any;
  nm_anexo: any;
  cidades: any;
  DadosPessoaisFormGroup: FormGroup;
  DadosProfissionaisFormGroup: FormGroup;
  DocumentosFormGroup: FormGroup;
  ContatoFormGroup: FormGroup;
  EducacaoFormGroup: FormGroup;
  ExperienciaFormGroup: FormGroup;
  formacao: any;
  regiao_servico_militar: any;
  num_dispensa: any;
  outra_cidade: any;

  constructor(
    private route: ActivatedRoute,
    private curriculoService: CurriculoService,
    private spinner: NgxSpinnerService,
    private _location: Location,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private cdref: ChangeDetectorRef,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.route.params.subscribe(res => {
      this.idCurriculo = res.id
      if (this.idCurriculo) {
        this.getCurriculo();
      }
    }); 
    this.baseImg = localStorage.getItem('base_img');

    this.DadosPessoaisFormGroup = this.formBuilder.group({
      nm_candidato: ['', Validators.required],
      sexo: ['', Validators.required],
      estado_civil: ['', Validators.required],
      dt_nascimento: ['', Validators.required],
      nm_mae: ['', Validators.required],
    });
    this.DadosProfissionaisFormGroup = this.formBuilder.group({
      deficiencia: ['', Validators.required],
      pretencao_salarial: ['', Validators.required],
      cargo: ['', Validators.required],
    });
    this.DocumentosFormGroup = this.formBuilder.group({
      cpf: ['', Validators.required],
      rg: ['', Validators.required],
      orgao_expeditor: ['', Validators.required],
    });
    this.ContatoFormGroup = this.formBuilder.group({
      tel_celular: ['', Validators.required],
      email: ['', Validators.required],
      rua: ['', Validators.required],
      numero: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: [''],
      outra_cidade: ['']
    });
    this.ExperienciaFormGroup = this.formBuilder.group({
      atividade_funcao: [null],
      dt_admissao: [''],
      motivo_demissao: ['']
    });
    this.EducacaoFormGroup = this.formBuilder.group({
      escolaridade: ['', Validators.required],
      formacao: [''],
      semestre: [''],
      situacao: [''],
      turno_faculdade: [''],
    });

  }
  
  changeValidators() {
    if (this.escolaridade == 5) {
      this.EducacaoFormGroup = this.formBuilder.group({
        escolaridade: [this.escolaridade, Validators.required],
        formacao: [this.formacao, Validators.required],
        semestre: [this.semestre, Validators.required],
        situacao: [this.situacao, Validators.required],
        turno_faculdade: [this.turno_faculdade, Validators.required],
      });
    } else if (this.escolaridade == 6) {
      this.EducacaoFormGroup = this.formBuilder.group({
        escolaridade: [this.escolaridade, Validators.required],
        formacao: [this.formacao, Validators.required],
        semestre: [this.semestre, null],
        situacao: [this.situacao, null],
        turno_faculdade: [this.turno_faculdade, null],
      });
    } else {
      this.EducacaoFormGroup = this.formBuilder.group({
        escolaridade: [this.escolaridade, Validators.required],
        formacao: [null, null],
        semestre: [null, null],
        situacao: [null, null],
        turno_faculdade: [null, null],
      });
    }
    if (this.ultima_empresa.length > 0) {
      this.ExperienciaFormGroup = this.formBuilder.group({
        atividade_funcao: [this.atividade_funcao, Validators.required],
        dt_admissao: [this.dt_admissao, Validators.required],
        motivo_demissao: [this.motivo_demissao, Validators.required]
      });
    } else {
      this.ExperienciaFormGroup = this.formBuilder.group({
        atividade_funcao: [null, null],
        dt_admissao: ['', null],
        dt_demissao: ['', null],
        motivo_demissao: [null, null]
      });
    }
    if (this.uf == 'DF' || this.uf == 'GO') {
      this.ContatoFormGroup = this.formBuilder.group({
        tel_celular: [this.tel_celular, Validators.required],
        email: [this.email, Validators.required],
        rua: [this.rua, Validators.required],
        numero: [this.numero, Validators.required],
        uf: [this.uf, Validators.required],
        cidade: [this.cidade, Validators.required],
        outra_cidade: [this.outra_cidade, null],
      });
    } else {
      this.ContatoFormGroup = this.formBuilder.group({
        tel_celular: ['', Validators.required],
        email: ['', Validators.required],
        rua: ['', Validators.required],
        numero: ['', Validators.required],
        uf: [this.uf, Validators.required],
        cidade: [null, null],
        outra_cidade: [null, Validators.required]
      });
    }
  }


  getCurriculo() {
    this.spinner.show();
    this.curriculoService.get(this.idCurriculo).subscribe(
      data => {
        const response = (data as any);

        this.nm_candidato = response[0].nm_candidato;
        this.sexo = response[0].sexo;
        this.estado_civil = response[0].estado_civil;
        this.dt_nascimento = response[0].dt_nascimento;
        this.nm_mae = response[0].nm_mae;
        this.nm_pai = response[0].nm_pai;
        this.nm_img = response[0].nm_img;

        this.deficiencia = response[0].deficiencia;
        this.cid_deficiencia = response[0].cid_deficiencia;
        this.pretencao_salarial = response[0].pretencao_salarial;
        this.cargo = response[0].cargo;
        this.cargo2 = response[0].cargo2;
        this.cargo3 = response[0].cargo3;
        this.turno = response[0].turno;
        this.pretencao_salarial = response[0].pretencao_salarial;
        this.observacoes = response[0].observacoes;

        this.tel_residencial = response[0].tel_residencial;
        this.tel_celular = response[0].tel_celular;
        this.tel_recados = response[0].tel_recados;
        this.email = response[0].email;
        this.cep = response[0].cep;
        this.rua = response[0].rua;
        this.numero = response[0].numero;
        this.uf = response[0].uf;
        this.cidade = response[0].cidade;

        this.cpf = response[0].cpf;
        this.rg = response[0].rg;
        this.orgao_expeditor = response[0].orgao_expeditor;
        this.cnh = response[0].cnh;
        this.servico_militar = response[0].servico_militar;
        this.regiao_servico_militar = response[0].regiao_servico_militar;
        this.num_dispensa = response[0].num_dispensa;

        this.ultima_empresa = response[0].ultima_empresa;
        this.atividade_funcao = response[0].atividade_funcao;
        this.nm_chefe = response[0].nm_chefe;
        this.tel_chefe = response[0].tel_chefe;
        this.dt_admissao = response[0].dt_admissao;
        this.dt_demissao = response[0].dt_demissao;
        this.motivo_demissao = response[0].motivo_demissao;
        this.experiencia_profissional = response[0].experiencia_profissional;

        this.ultima_empresa2 = response[0].ultima_empresa2;
        this.atividade_funcao2 = response[0].atividade_funcao2;
        this.nm_chefe2 = response[0].nm_chefe2;
        this.tel_chefe2 = response[0].tel_chefe2;
        this.dt_admissao2 = response[0].dt_admissao2;
        this.dt_demissao2 = response[0].dt_demissao2;
        this.motivo_demissao2 = response[0].motivo_demissao2;
        this.experiencia_profissional2 = response[0].experiencia_profissional2;

        this.escolaridade = response[0].escolaridade;
        this.alfabetizado = response[0].alfabetizado;
        this.turno_faculdade = response[0].turno_faculdade;
        this.situacao = response[0].situacao;
        this.formacao = response[0].formacao;
        this.cursos = response[0].cursos;
        this.semestre = response[0].semestre;

        // this.nm_anexo = (response[0].nm_anexo) ? "https://docs.google.com/gview?url=https://prod.myclick.com.br/grupohumana/api/public/anexos/"+response[0].nm_anexo+"&embedded=true" : null;
        this.nm_anexo = (response[0].nm_anexo) ? this.getSafeUrl("https://docs.google.com/viewerng/viewer?url=https://prod.myclick.com.br/grupohumana/api/public/anexos/"+response[0].nm_anexo+"&embedded=true") : null;
        

        this.getAllSelects();
        this.getCidades();
        this.spinner.hide();
      }, error => {
        console.error(error);
      })
  }

  getCidades() {
    let arr: any = {
      "uf": this.uf
    };
    arr = JSON.stringify(arr);
    this.curriculoService.getAllCidades(arr).subscribe(data => {
        const response = (data as any);
        this.cidades = response; 
    });
  }

  save() {
    this.spinner.show()
    let arr: any = {
      "nm_candidato": this.nm_candidato,
      "sexo": this.sexo,
      "estado_civil": this.estado_civil,
      "dt_nascimento": this.dt_nascimento,
      "nm_mae": this.nm_mae,
      "nm_pai": this.nm_pai,
      "deficiencia": this.deficiencia,
      "cid_deficiencia": this.cid_deficiencia,
      "pretencao_salarial": this.pretencao_salarial,
      "cargo": this.cargo,
      "cargo2": this.cargo2,
      "cargo3": this.cargo3,
      "cpf": this.cpf,
      "rg": this.rg,
      "orgao_expeditor": this.orgao_expeditor,
      "cnh": this.cnh,
      "tel_residencial": this.tel_residencial,
      "tel_celular": this.tel_celular,
      "tel_recados": this.tel_recados,
      "email": this.email,
      "cep": this.cep,
      "rua": this.rua,
      "numero": this.numero,
      "uf": this.uf,
      "cidade": this.cidade,
      "ultima_empresa": this.ultima_empresa,
      "atividade_funcao": this.atividade_funcao,
      "nm_chefe": this.nm_chefe,
      "tel_chefe": this.tel_chefe,
      "dt_admissao": this.dt_admissao,
      "dt_demissao": this.dt_demissao,
      "motivo_demissao": this.motivo_demissao,
      "experiencia_profissional": this.experiencia_profissional,
      "ultima_empresa2": this.ultima_empresa2,
      "atividade_funcao2": this.atividade_funcao2,
      "nm_chefe2": this.nm_chefe2,
      "tel_chefe2": this.tel_chefe2,
      "dt_admissao2": this.dt_admissao2,
      "dt_demissao2": this.dt_demissao2,
      "motivo_demissao2": this.motivo_demissao2,
      "experiencia_profissional2": this.experiencia_profissional2,
      "escolaridade": this.escolaridade,
      "alfabetizado": this.alfabetizado,
      "formacao": this.formacao,
      "situacao": this.situacao,
      "turno_faculdade": this.turno_faculdade,
      "cursos": this.cursos,
      "semestre": this.semestre,
      "turno": this.turno,
      "observacoes": this.observacoes,
    };
    arr = JSON.stringify(arr);
    if (this.idCurriculo) {
      this.curriculoService.edit(arr, this.idCurriculo).subscribe(data => {
        if (data) {
          const response = (data as any);
          if(response.sucesso != false) {
          this.toastr.success('Registro alterado com sucesso!', 'Sucesso!');
          window.scroll(0, 0);
        } else {
            this.spinner.hide();
            this.toastr.error('Erro ao salvar registro!', 'Erro!');
            console.error(response);
          }
        }
          this.spinner.hide();
      }, error => {
        console.error(error);
        this.spinner.hide();
        this.toastr.error('Erro ao alterar registro!', 'Erro!');
      })
    } else {
      this.curriculoService.post(arr).subscribe(data => {
        if (data) {
          const response = (data as any);
          if(response.sucesso != false) {
          this.toastr.success('Registro salvo com sucesso!', 'Sucesso!');
          window.scroll(0, 0);
          this.spinner.hide();
          // console.log(response);
          this.router.navigate(['/curriculo/' + response.id_curriculo]);
          } else {
            this.spinner.hide();
            this.toastr.error('Erro ao salvar registro!', 'Erro!');
            console.error(response);
          }
        }
      }, error => {
        console.error(error);
        this.spinner.hide();
        this.toastr.error('Erro ao salvar registro!', 'Erro!');
      })
    }
  }

  getSafeUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  getAllSelects() {
    this.curriculoService.getAllSelects().subscribe(data => {
      if (data != 'null') {
        const response = (data as any);
        this.cargos = response.cargos;
        this.cursos_formacao = response.cursos;
      }
    });
  }

  back() {
    this._location.back();
  }

  ngOnInit() {
        this.getAllSelects();
        this.getCidades();
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

}
