import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ProcessosSeletivosService } from 'src/app/services/processos-seletivos/processos-seletivos.service';
import { EventEmitterService } from '../services/emitter/event-emitter.service';


@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.css']
})
export class KanbanComponent implements OnInit {

  tracks: any;
  id: any;
  sub: any;

  constructor(
    public processosSeletivosService: ProcessosSeletivosService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute
  ) {
    this.route.params.subscribe(res => {
      this.id = res.id
      if (this.id) {
        this.getAllTracks(this.id);
      }
    });
    this.emmiter();
  }

  emmiter() {
    this.sub = EventEmitterService.get('novoVinculo').subscribe(data => {
      this.getAllTracks(this.id);
    });
  }

  getAllTracks(id) {
    this.spinner.show();
    this.processosSeletivosService.getAllEtapas(id).subscribe(data => {
      if (data != 'null') {
        const response = (data as any);
        this.tracks = response;
        // console.log(this.tracks); 
        this.spinner.hide();
      }
    }, error => {
      this.spinner.hide();
      console.error(error);
    });
  }


  // getTracks() {
  //   this.tracks = [
  //     {
  //       "title": "Todo",
  //       "id": "todo",
  //       "tasks": [
  //         {
  //           "id": "first-task",
  //           "title": "First Task",
  //           "description": "This is my first task"
  //         },
  //         {
  //           "id": "first-task",
  //           "title": "2 Task",
  //           "description": "This is my first task"
  //         }
  //       ]
  //     },
  //     {
  //       "title": "In Progress",
  //       "id": "inprogress",
  //       "tasks": [
  //         {
  //           "id": "seconf-task",
  //           "title": "Second Task",
  //           "description": "This is my first task"
  //         }
  //       ]
  //     },
  //     {
  //       "title": "D-Done",
  //       "id": "ddone",
  //       "tasks": [
  //         {
  //           "id": "third-task",
  //           "title": "Third Task",
  //           "description": "This is my first task"
  //         }
  //       ]
  //     },
  //     {
  //       "title": "QA Pass",
  //       "id": "qapass",
  //       "tasks": [
  //         {
  //           "id": "fourth-task",
  //           "title": "Fourth Task",
  //           "description": "This is my first task"
  //         }
  //       ]
  //     }
  //   ]
  //   console.log(this.tracks)
  // }

  onTaskDrop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let currentId = event.container.element.nativeElement.id
      let currentData = event.container.data;
      this.trocaPosicao(currentData, currentId);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
      let previousId = event.previousContainer.element.nativeElement.id;
      let previousData = event.previousContainer.data;

      let currentId = event.container.element.nativeElement.id
      let currentData = event.container.data;

      this.trocaPosicao(previousData, previousId);
      this.trocaPosicao(currentData, currentId);
    }
  }


  trocaPosicao(arr, id) {
    console.log(arr)
    let arr2: any = {
      "arr": arr
    };
    arr2 = JSON.stringify(arr2);
    this.processosSeletivosService.trocaPosicao(arr2, id).subscribe(data => {
      if (data) {
        const response = (data as any);
        console.log(response)
      }
    }, error => {
      console.error(error);
    });
  }

  ngOnInit() {
    // this.getTracks();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

}
