import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../services/shared/shared.service';
import { EventEmitterService } from "../../../services/emitter/event-emitter.service";
import * as moment from 'moment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  notificacoes: Array<any>;
  count: any;
  arr: Array<any>;
  notify: any;
  sub: any;
  id_usuario: any;
  count_icon: string;

  constructor(private sharedService: SharedService) {
    moment.locale('pt-br');
    this.id_usuario = localStorage.getItem('id_user');
  }

  getNotification() {

    this.sharedService.getNotificacoes(this.id_usuario).subscribe(data => {
      if (data != 'null') {
        const response = (data as any);

        this.count = response.count[0].count;
        if(this.count > 100) {
          this.count_icon = '99+'
        } else {
          this.count_icon = this.count;
        }
        this.arr = response.list;

        let i = 0;
        this.arr.forEach(obj => {

          let str = moment(obj.data).fromNow()
          str.replace("há ", "");
          this.arr[i].data = str;

          i++;

        });

        this.notificacoes = this.arr


      }
    });
  }

  ngOnInit() {
    this.getNotification();
    this.notify = setInterval(() => {
      this.getNotification();
    }, 60000);

    this.sub = EventEmitterService.get('reloadNotification').subscribe(data => {
      if(data == "reload") {
        this.getNotification();
      }

    });

  }

  ngOnDestroy() {
    if (this.notify) {
      clearInterval(this.notify);
    }
    this.sub.unsubscribe();
  }

}
