import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { NgxSpinnerModule } from "ngx-spinner";
import { ChartsModule } from 'ng2-charts';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DatePipe } from '@angular/common';
import { BrMaskerModule } from 'br-mask';

//Components
import { HeaderComponent } from './components/shared/header/header.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { ContentComponent } from './components/shared/content/content.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { LoginComponent } from './components/shared/login/login.component';
import { Error404Component } from './error404/error404.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotificacoesListComponent } from './notificacoes/notificacoes-list/notificacoes-list.component';
import { NotificacaoViewComponent } from './notificacoes/notificacao-view/notificacao-view.component';
import { RecuperarSenhaComponent } from './components/shared/recuperar-senha/recuperar-senha.component';
import { NovaSenhaComponent } from './components/shared/nova-senha/nova-senha.component';
import { PerfilComponent } from './perfil/perfil.component';
import { NotificacoesFormComponent } from './notificacoes/notificacoes-form/notificacoes-form.component';
import { PrintpageComponent } from './curriculo/imprimir/printpage/printpage.component';
import { CurriculoSiteComponent } from './curriculo-site/curriculo-site.component';
import { KanbanComponent } from './kanban/kanban.component';
import { ProcessoSeletivoFormComponent } from './processos-seletivos/processo-seletivo-form/processo-seletivo-form.component';
import { ProcessoSeletivoListComponent } from './processos-seletivos/processo-seletivo-list/processo-seletivo-list.component';
import { CurriculoFormComponent } from './curriculo/curriculo-form/curriculo-form.component';
import { CurriculoListComponent } from './curriculo/curriculo-list/curriculo-list.component';
import { UsuarioFormComponent } from './usuarios/usuario-form/usuario-form.component';
import { UsuarioListComponent } from './usuarios/usuario-list/usuario-list.component';

//Services e Pipes
import { AuthGuardService } from './guards/auth-guard.service';
import { EventEmitterService } from './services/emitter/event-emitter.service';
import { SharedService } from './services/shared/shared.service';
import { CandeactivateService } from './services/candeactivate/candeactivate.service';
import { SlugifyPipe } from './pipes/slugify/slugify.pipe';
import { CurriculoService } from './services/curriculo/curriculo.service';
import { ProcessosSeletivosService } from './services/processos-seletivos/processos-seletivos.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    MenuComponent,
    ContentComponent,
    FooterComponent,
    Error404Component,
    DashboardComponent,
    LoginComponent,
    NotificacoesListComponent,
    NotificacaoViewComponent,
    RecuperarSenhaComponent,
    NovaSenhaComponent,
    NotificacoesFormComponent,
    PerfilComponent,
    SlugifyPipe,
    CurriculoFormComponent,
    CurriculoListComponent,
    UsuarioFormComponent,
    UsuarioListComponent,
    PrintpageComponent,
    CurriculoSiteComponent,
    KanbanComponent,
    ProcessoSeletivoFormComponent,
    ProcessoSeletivoListComponent 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(), 
    DataTablesModule,
    NgxSpinnerModule,
    ChartsModule,
    DragDropModule,
    BrMaskerModule
  ],
  providers: [
    AuthGuardService,
    EventEmitterService,
    SharedService,
    CurriculoService,
    DatePipe,
    CandeactivateService,
    SlugifyPipe,
    ProcessosSeletivosService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
