import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { CurriculoService } from 'src/app/services/curriculo/curriculo.service';



@Component({
  selector: 'app-curriculo-list',
  templateUrl: './curriculo-list.component.html',
  styleUrls: ['./curriculo-list.component.css']
})
export class CurriculoListComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  curriculos: any;
  dtTrigger: Subject<any> = new Subject();
  dtOptions: any = {};
  cargos: any;
  cargo: any = null;
  deficiencia: any = null;
  escolaridade: any = null;
  pretencao_salarial: any = null;
  sexo: any = null;
  uf: any = null;
  formacao: any = null;
  cursos_formacao: any;
  cidades: any;
  cidade: any = null;
  turno: any = null; 

  constructor(
    private curriculosService: CurriculoService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { }


  getAll() {
    this.spinner.show();
    this.curriculosService.getAll().subscribe(data => {
      if (data != 'null') {
        const response = (data as any);
        this.curriculos = response;
        this.spinner.hide();
        // this.rerender();
        this.dtTrigger.next();
      }
    });
  }

  getAllSelects() {
    this.curriculosService.getAllSelects().subscribe(data => {
      if (data != 'null') {
        const response = (data as any);
        this.cargos = response.cargos;
        this.cursos_formacao = response.cursos;
      }
    });
  }

  getCidades(cidade = null) {
    let arr: any = {
      "uf": this.uf
    };
    arr = JSON.stringify(arr);
    this.curriculosService.getAllCidades(arr).subscribe(data => {
      const response = (data as any);
      this.cidade = cidade;
      this.cidades = response;
    });
  }

  zeraInputs() {
    if(this.escolaridade != 5 || this.escolaridade != 6) {
      this.formacao = null
    }

    if(this.uf == null) {
      this.cidade = null
    }
  }

  // delete(id) {
  //   if (confirm("Deseja realmente deletar este registro?")) {
  //     this.curriculosService.delete(id).subscribe(
  //       data => {
  //         const response = (data as any);
  //         if (response == true) {
  //           // this.toastr.success('Registro excluído com sucesso!', 'Sucesso!');
  //         } else {
  //           // this.toastr.error('Erro ao excluir registro!', 'Erro!');
  //         }

  //         this.getAll();
  //         this.dtTrigger.next();
  //         this.rerender();
  //       }, error => {
  //         console.error('Erro');
  //       }
  //     )
  //   }
  // }

  delete(id) {
    this.spinner.show();
    if (confirm("Deseja realmente deletar este registro?")) {
      this.curriculosService.delete(id).subscribe(
        data => {
          const response = (data as any);
          if (response) {
            this.toastr.success('Registro excluído com sucesso!', 'Sucesso!');
            this.getAll();
            this.rerender();
          } else {
            this.toastr.error('Erro ao excluir registro!', 'Erro!');
          }
          this.spinner.hide();          
        }, error => {
          this.spinner.hide();
          console.error(error);
        }
      )
    } else {
      this.spinner.hide();
    }
  }

  rerender(): void {
    if(this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
      });
    }
  }

  pesquisa() {
    this.spinner.show();
    let arr: any = {
      "cargo": this.cargo,
      "cidade": this.cidade,
      "deficiencia": this.deficiencia,
      "escolaridade": this.escolaridade,
      "pretencao_salarial": this.pretencao_salarial,
      "sexo": this.sexo,
      "uf": this.uf,
      "formacao": this.formacao,
      "turno": this.turno
    };
    arr = JSON.stringify(arr);
    sessionStorage.setItem('filtros', arr);
    this.curriculosService.postPesquisa(arr).subscribe(data => {
      if (data) {
        const response = (data as any);
        if (response.sucesso != false) {
          this.curriculos = data;
          this.rerender();
          this.dtTrigger.next();
          this.spinner.hide();
        } else {
          console.error(response);
          this.spinner.hide();
        }
        this.spinner.hide();
      }
    });
  }

  limparFiltros() {
      this.cargo = null;
      this.cidade = null;
      this.deficiencia = null;
      this.escolaridade = null;
      this.pretencao_salarial = null;
      this.sexo = null;
      this.uf = null;
      this.formacao = null;
      this.turno = null;
      this.pesquisa();
      sessionStorage.removeItem('filtros');
  }

  carregarFiltros() {
    let filtros: any = sessionStorage.getItem('filtros');
    if(sessionStorage.length > 0) {
      filtros = JSON.parse(filtros);

      this.cargo = filtros.cargo;
      this.deficiencia = filtros.deficiencia;
      this.escolaridade = filtros.escolaridade;
      this.pretencao_salarial = filtros.pretencao_salarial;
      this.sexo = filtros.sexo;
      this.uf = filtros.uf;
      this.formacao = filtros.formacao;
      this.cidade = filtros.cidade;
      this.turno = filtros.turno;

      this.getCidades(this.cidade);
      this.pesquisa();
    } else {
      this.getAll();
    }
  }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "url": "https://cdn.datatables.net/plug-ins/1.10.20/i18n/Portuguese-Brasil.json"
      },
      "bDestroy": true
    }

    this.getAllSelects();
    this.carregarFiltros();

    

  }

}
