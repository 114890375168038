import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  baseApiPath: any = localStorage.getItem('base_url');
  headers: any;

  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
  }

  getAll() {
    return this.http.get(this.baseApiPath + '/login-painel');
  }

  get(idEmpresa) {
    return this.http.get(this.baseApiPath + '/login-painel/' + idEmpresa);
  }

  putTrocaSenha(data) {
    return this.http.put(this.baseApiPath + '/troca-senha/', data, this.headers);
  }

  edit(data, idEmpresa) {
    return this.http.put(this.baseApiPath + '/login-painel/' + idEmpresa, data, this.headers);
  }

  new(data) {
    return this.http.post(this.baseApiPath + '/login-painel/', data, this.headers);
  }

  login(data) {
    return this.http.post(this.baseApiPath + '/login-painel/', data, this.headers);
  }

  recuperarSenha(data) {
    return this.http.post(this.baseApiPath + '/recuperar-senha/', data, this.headers);
  }

  getToken(token) {
    return this.http.get(this.baseApiPath + '/recuperar-senha/get-token/'+token, this.headers);
  }

  delete(idEstabelecimento) {
    return this.http.delete(this.baseApiPath + '/login-painel/' + idEstabelecimento, this.headers);
  }

}
